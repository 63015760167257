import { Directive, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Directive({
  selector: '[rcgActiveRoute]',
  standalone: true,
})
export class RcgActiveRouteDirective implements OnInit, OnDestroy {
  @Input() rcgActiveRoute: string | string[] | null = null;
  @Input() rcgQueryParams: { [key: string]: string } | null = null;

  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  private subscription: Subscription | null = null;
  private readonly className = 'rcg-active-route';

  ngOnInit() {
    if (!this.rcgActiveRoute || !this.rcgQueryParams) {
      return;
    }
    this.subscription = this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe(() => {
      this.updateActiveStatus();
    });

    // Initial check
    this.updateActiveStatus();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private updateActiveStatus() {
    if (!this.el?.nativeElement) return;
    if (this.isActive(this.rcgActiveRoute, this.rcgQueryParams)) {
      this.renderer.addClass(this.el.nativeElement, this.className);
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.className);
    }
  }

  isActive(routerLink: string | string[] | null, queryParams?: { [key: string]: string } | null): boolean {
    if (!routerLink || !queryParams || Object.keys(queryParams)?.length === 0) {
      return false;
    }

    const link = Array.isArray(routerLink) ? routerLink : [routerLink || ''];
    const urlTree = this.router.createUrlTree(link, {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
    });

    return this.router.isActive(urlTree, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
